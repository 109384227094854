export const masterClassCategories = {
    'ssc-exams' : {id:"5e6189e15f66e94f14a21f94",title:'SSC'},
    'bank-exams' : {id:"5e6189e15f66e94f14a21f8c",title:'Bank'},
    'police-exams' : {id:"5e6189e15f66e94f14a21f92",title:'Police'},
    'railways-exams' : {id:"5e6189e15f66e94f14a21f93",title:'Railways'},
    'engineering-recruitment-exams' : {id:"5e6189e15f66e94f14a21f8f",title:'Engineering Recruitment Exams'},
    'defence-exams' : {id:"5e6189e15f66e94f14a21f8d",title:'Defence'},
    'state-govt-exams' : {id:"5e6189e15f66e94f14a21f95",title:'State Govt.'},
    'ias-exams' : {id:"5f8e7cdd8b924a652e9217e6",title:'Civil Service (IAS)'},
    'teaching-exams':{id:"5e6189e15f66e94f14a21f96",title:'Teaching Exams'},
    'cuet-ug-entrance':{id:"623b0339b5959edab280f631",title:'CUET and UG Entrance'},
    'judiciary-exams':{id:"64ec5163d8987aa5fe3b60ae",title:'Judiciary Exams'},
    'regulatory':{id:'66719b5c93d99e3d5995afb2',title:'Regulatory Body Exams'}
}

export function getMasterExplorePageMetatitle(key){
    if(key!=='Engineering Recruitment Exams'){
        return `Free Online Live Classes for ${key} Exams with Best tips & Tricks!`
    }

    return `Free Online Live Classes for ${key}: Join Now`
    
}

export function getMasterExplorePageMetaDesc(key){
    if(key!=='Engineering Recruitment Exams'){
        return `Join Our Free Online Live Classes for ${key} Exams specially designed for students with Advance level Maths, Tricks for Quant, Daily Quiz, previous year questions.`
    }

    return `Join Our Free Online Live Classes for ${key} specially designed for students with Advance level Maths, Tricks for Quant, Daily Quiz,old Papers.`
    
}

export function getSeoData(url,superGroupDetails) {
    //setting seo fields
    let title = superGroupDetails?.metaTitle ? superGroupDetails?.metaTitle : "Free Online Live Classes for Govt. Exam Preparation"
    let metaDesc = superGroupDetails?.metaDesc ? superGroupDetails?.metaDesc : "Join Our Free Online Live Classes for Govt. exams preparation. Specially designed with Advance level Practice, Tips and Tricks, Daily Quiz, Previous Papers and more.";
    let seoData:any = {
      tags: [
        { type: 'title' , attributes: { title: title}},
        { type: 'meta' , attributes: {name: 'description' , content:metaDesc}},
        { type: 'meta', attributes: {property:"og:locale", content:"en_US"}},
        { type: 'meta', attributes: {property:"og:type", content:"website" }},
        { type: 'meta', attributes: {property:"og:title", content:title}},
        { type: 'meta', attributes: {property:"og:description", content:metaDesc }},
        { type: 'meta', attributes: {property:"og:site_name", content:"Testbook" }},
        { type: 'meta', attributes: {property:"og:url", content:url }},
        { type: 'meta', attributes: {property:"og:image", content:"https://cdn.testbook.com/ogexlogo/free-live-classes.png"}},
        { type: 'meta', attributes: {name:"twitter:card", content:metaDesc }},
        { type: 'meta', attributes: {name:"twitter:site", content:"@testbookdotcom" }},
        { type: 'meta', attributes: {name:"twitter:title", content:title }},
        { type: 'meta', attributes: {name:"twitter:image", content:"https://cdn.testbook.com/meta-data/tb-og-images/tb-social.png" }}
      ]
    }

    seoData.tags.push({ type: 'link', attributes: {rel:"canonical", href:url}});
      
    return seoData;

    // this.seoService.addSeo(seoData);

}

export function checkSlugInPathName(pathName){
    for(let key of Object.keys(masterClassCategories)){
        if(pathName.includes(key)){
            return key;
        }
    }
    return '';
}


export function validateMasterClassSlugOrId(slugOrId){
    for(let key of Object.keys(masterClassCategories)){
        if(key === slugOrId){
            return masterClassCategories[key].id; 
        }
        else if(masterClassCategories[key].id === slugOrId){
            return slugOrId;
        }
    }
    return ''
}

export function getMasterClassSlug(id){
    for(let key of Object.keys(masterClassCategories)){
        if(masterClassCategories[key].id === id || key === id){
            return key;
        }
    }
    return ''
}

export function getMasterClassTitle(idOrSlug){
    for(let key of Object.keys(masterClassCategories)){
        if(key=== idOrSlug || masterClassCategories[key].id === idOrSlug){
            return masterClassCategories[key].title;
        }
    }
    return ''
}